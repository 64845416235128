import React, { useState } from "react";
import { ref as databaseRef, update } from "firebase/database";
import { database } from "./firebase";

const ConfirmEmailPopup = ({ formData, setFormData, selectedFile, setSelectedFile, onConfirm, onCancel, setChooseEmailFrom }) => {
    const emailOptions = formData.emailOptions;
    const [isDisabled, setIsDisabled] = useState(false)
    const handleFileChange = (e) => setSelectedFile(e.target.files[0]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form submitted');
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.fromEmail)) {
            alert('Invalid from email address.');
            return;
        }
        if (!emailRegex.test(formData.toEmail)) {
            alert('Invalid to email address.');
            return;
        }

        if (!formData.subject) {
            alert("Please fill in the subject.");
            return;
        }
        setIsDisabled(true)
        const { fromEmail, toEmail, ccEmail, subject, content } = formData
        const formDataToSend = {
            fromEmail,
            toEmail,
            ccEmail,
            subject,
            content,
        }
        try {
            await fetch('https://common.autoservice.ai/send-mail-for-sales', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formDataToSend),
            });

            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().split("T")[0];
            const dataRef = databaseRef(
                database,
                `formData/${formData.username}/${formattedDate}/${formData.currentDate}`
              );
            
            await update(dataRef, {
                ...formDataToSend,
                ...formData
            })

        } catch (error) {
            console.error('Error sending request:', error);
        }
        // Navigate to success page
        window.location.reload();
    };

    return (
        <div className="loading">
            <div className="responsive-form confirm-popup">
                <h3 style={{ textAlign: 'center' }}>Confirm Email</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>From:</label>
                        <select
                            value={formData.fromEmail}
                            onChange={(e) => {
                                const chooseEmailFrom = emailOptions.find(data => data.email === e.target.value)
                                if (chooseEmailFrom) {
                                setFormData({
                                    ...formData, content: `${formData.dealerName}\n${formData.contentOriginal}

${chooseEmailFrom.name}
${chooseEmailFrom.email}
${chooseEmailFrom.phone}`, fromEmail: e.target.value, ccEmail: e.target.value
                                    })
                                }
                            }}
                        >
                            <option key="" value="">
                   
                            </option>
                            {emailOptions.map((email, index) => (
                                <option key={index} value={email.email}>
                                    {email.email}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Dealer Email:</label>
                        <input
                            type="text"
                            value={formData.toEmail}
                            onChange={(e) => setFormData({ ...formData, toEmail: e.target.value })}
                            placeholder="Enter recipient email"
                        />
                    </div>

                    <div className="form-group">
                        <label>Contact Name:</label>
                        <input
                            type="text"
                            value={formData.dealerName}
                            onChange={(e) => {
                                const chooseEmailFrom = emailOptions.find(data => data.email === formData.fromEmail)
                                setFormData({
                                    ...formData, content: `${e.target.value}\n${formData.contentOriginal}

${chooseEmailFrom.name}
${chooseEmailFrom.email}
${chooseEmailFrom.phone}`, dealerName: e.target.value
                                })
                            }}
                            placeholder="Enter contact name"
                        />
                    </div>

                    <div className="form-group">
                        <label>CC:</label>
                        <input
                            type="text"
                            value={formData.ccEmail}
                            onChange={(e) => setFormData({ ...formData, ccEmail: e.target.value })}
                            placeholder="Enter CC email (optional)"
                        />
                    </div>

                    <div className="form-group">
                        <label>Subject:</label>
                        <input
                            type="text"
                            value={formData.subject}
                            onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                            placeholder="Enter subject"
                        />
                    </div>

                    <div className="form-group">
                        <label>Content:</label>
                        <textarea
                            value={formData.content}
                            onChange={(e) => setFormData({ ...formData, content: e.target.value })}
                            rows={5}
                            placeholder="Write your email content here"
                        />
                    </div>

                    {/* <div className="form-group">
                        <label>Attachment:</label>
                        <input type="file" onChange={handleFileChange} />
                    </div> */}

                    <div className="button-group">
                        {/* <button type="button" onClick={onCancel} className="cancel-button">
                            Cancel
                        </button> */}
                        <button type="submit" className="submit-button" disabled={isDisabled}>
                            OK
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ConfirmEmailPopup;
